import { NotFoundLayout } from '@/components/shared/layouts/NotFoundLayout/NotFoundLayout';
import { linkType } from '@/components/shared/navigations/BottomNavigation/BottomNavigation';
import { ColorTypeOptions } from '@/components/shared/navigations/CoreNavigation/CoreNavigation';
import { getSafeLocale } from '@/utils/locale';
import { GetStaticPropsContext } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import { FC } from 'react';
import styles from '../styles/404.module.scss';

const NotFound: FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<NextSeo title="404" />
			<NotFoundLayout
				colorType={ColorTypeOptions.DIMMED}
				colorTypeActive={ColorTypeOptions.DIMMED}
				bottomNavigation={{ leftLink: linkType.COLLECTION, rightLink: linkType.STORIES }}
			>
				<Head>
					<title>404</title>
				</Head>
				<div className={styles.notFound}>
					<div className={styles.notFoundContainer}>
						<img src="/assets/icons/404.svg" alt="404" className={styles.notFoundImage} />
						<h1 className={styles.notFoundTitle}>{t('sorry-i-just-dont-feel-the')}</h1>
						<h2 className={styles.notFoundSubtitle}>{t('can-we-take-a-break')}</h2>
					</div>
				</div>
			</NotFoundLayout>
		</>
	);
};

export async function getStaticProps(context: GetStaticPropsContext) {
	const { locale } = context;

	return {
		props: {
			...(await serverSideTranslations(getSafeLocale(locale), ['common'])),
		},
	};
}

export default NotFound;
