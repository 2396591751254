import { FC, Fragment, ReactNode } from 'react';

import Footer from '@/components/shared/footers/MainFooter/MainFooter';
import { Modals } from '@/components/shared/modals/Modals';
import BottomNavigation, {
	IBottomNavigationProps,
} from '@/components/shared/navigations/BottomNavigation/BottomNavigation';
import Navigation, { ColorTypeOptions } from '@/components/shared/navigations/CoreNavigation/CoreNavigation';
import { CookieBanner } from '@/components/shared/cookie-banner/CookieBanner';

export interface INotFoundLayout {
	colorType?: ColorTypeOptions;
	colorTypeActive?: ColorTypeOptions;
	children?: ReactNode;
	bottomNavigation?: Pick<IBottomNavigationProps, 'leftLink' | 'rightLink'>;
}

export const NotFoundLayout: FC<INotFoundLayout> = ({ colorTypeActive, colorType, bottomNavigation, children }) => {
	return (
		<Fragment>
			<CookieBanner />
			<Modals />
			<Navigation colorType={colorType} colorTypeActive={colorTypeActive} />
			{children}
			<BottomNavigation {...bottomNavigation} />
			<Footer />
		</Fragment>
	);
};
